<template lang="pug">
v-container(fluid).transparent.pa-0
  v-layout(row pa-3).grey.white--text
    v-flex.no-print
      v-img.black-border(
        v-if="job.design.image.url"
        aspect-ratio="1"
        height="150px"
        width="150px"
        :src="job.design.image.url"
      )
    v-flex(xs12).pa-3.body-1
      v-layout(row)
        v-flex.text-xs-left.font-weight-black.display-1 {{job.design.name}}
          span(v-if="job.subtitle")  - {{job.subtitle}}
      v-layout(row)
        v-flex.text-xs-left {{job.design.subtitleText}}
      v-layout(row)
        v-flex.text-xs-left 
          span.font-weight-bold quantity: 
          | {{job.quantity}}
  div(v-for="(productGroup, i) in job.productGroups" :key="i")
    ProductGroup(:productGroup='productGroup').mt-3

  
</template>

<script>
import ProductGroup from './product-group'

export default {
  components: { ProductGroup },
  props: {
    job: { type: Object }
  },
}
</script>

<style lang="stylus" scoped>
.black-border
  border solid black 1px
.thick-left-border
  border-left solid black 4px
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
