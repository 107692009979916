<template lang="pug">
v-container(fluid).transparent.pa-0
  v-layout(row justify-start align-center)
    v-flex(v-if="productGroup.product.image.url").no-print
      v-img(
        aspect-ratio=1
        height="100px"
        width="100px"
        contain
        :src="productGroup.product.image.url"
      )
    v-flex(pl-3 xs11).title {{productGroup.product.displayName}}
  LineItems.mb-3.mt-2(
    :lines="removeZeroQuantityLineItems(productGroup.lineItems)"
    :productGroup="productGroup"
    :order='order'
    readOnly=true
  )

</template>

<script>
import LineItems from '../line-items-quantities'

export default {
  components: { LineItems },
  props: {
    productGroup: { type: Object }
  },
  computed: {
    order () { return this.$store.getters['orders/item'] }
  },
  methods: {
    removeZeroQuantityLineItems (lineItems) {
      return lineItems.filter(line => {
        return line.quantity !== 0 // && line.quantityFinal !== 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.black-border
  border solid black 1px
.thick-left-border
  border-left solid black 4px
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
