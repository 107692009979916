<template lang="pug">
v-layout.body-2.font-weight-bold(row px-4 pt-3)
  v-flex(xs1)
  v-flex(xs5)
  v-flex.text-xs-center(xs3) 
    span(v-if="unlockQuantityFinal") {{ quantityFinal }}
    span(v-else) {{ quantity }}
</template>

<script>
export default {
  props: ['productGroup', 'order'],
  computed: {
    subtotal () { return this.productGroup.subtotal },
    subtotalFinal () { return this.productGroup.subtotalFinal },
    quantity () { return this.productGroup.quantity },
    quantityFinal () { return this.productGroup.quantityFinal },
    unlockQuantityFinal () {
      const order = this.$store.getters['orders/item']
      return ['ready to ship', 'invoice', 'paid'].includes(order.status.name)
    }
  }
}
</script>
