import { render, staticRenderFns } from "./product-group.vue?vue&type=template&id=0e0234ea&scoped=true&lang=pug&"
import script from "./product-group.vue?vue&type=script&lang=js&"
export * from "./product-group.vue?vue&type=script&lang=js&"
import style0 from "./product-group.vue?vue&type=style&index=0&id=0e0234ea&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e0234ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VImg,VLayout})
