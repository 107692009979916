<template lang="pug">
v-layout.line-item.body-1(row px-4 align-center)
  v-flex(xs5)
    v-layout(row justify-start)
      v-flex.pa-1
        v-avatar(size=28 tile)
          v-img(:src="line.color.image.url")
      v-flex {{line.color.name}}

  v-flex(xs1).text-xs-center.text-uppercase {{line.size.name}}

  v-flex(xs3).text-xs-center {{ line.quantityOrdered }}
  v-flex(xs3).text-xs-center {{ line.quantityReceived }}


</template>
<script>

export default {
  props: {
    line: { required: true, type: Object },
    order: { required: true, type: Object },
  },
  methods: {
    changeQuantity (payload) { this.$emit('changeQuantity', payload) },
     
  },

}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #CFD8DC 1px
</style>
