<template lang="pug">
div
  JobDetail
    

</template>

<script>
import JobDetail from './job-detail-quantities'

export default {
  components: {
    JobDetail,
  },
  // async beforeMount () {
  //   this.$store.dispatch('orders/_LOAD_JOBS_FOR_DOCUMENT')
  // }
}
</script>
