<template lang="pug">
div
  LineItemHeader
  template(v-for="line in lines")
    LineItem(
      :key="line._id"
      :line="line"
      :order="order"
      @changeQuantity="changeQuantity"
    )
  LineItemFooter(:productGroup="productGroup", :order='order')
</template>
<script>
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  props: {
    lines: { type: Array, required: true },
    order: { type: Object, required: true },
    productGroup: { type: Object, required: true },
  },

  methods: {
    changeQuantity (payload) {
      // report quantity change back to parent
      this.$emit('changeQuantity', payload)
    }
  },
  
  components: { LineItem, LineItemHeader, LineItemFooter },
}
</script>
