<template lang="pug">
div
  DocHeading product breakdown (per design)
  div(v-for="(job, i) in jobs")
    DocJob(:job='job')
</template>

<script>
import DocHeading from '../_heading'
import DocJob from './job'

export default {
  components: { DocHeading, DocJob },
  computed: {
    jobs () { return this.$store.getters['jobs/items'] },
  },
}
</script>
