<template lang="pug">
div
  DetailFull(v-if="loaded")
    template(slot="title-prefix")
      | Schedule Item: 
    template(slot="title")
      | {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} - 
      span.font-weight-bold {{item.location.template.name}}
    template(slot="controls")
      Controls
    template(slot="form")
      v-container
        document-quantities
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailFull from '@/components/base/detail-full-page'
import { detail as PIDetail } from '@/modules/production-instances'
import Controls from './controls'
import DocumentQuantities from '@/modules/orders/components/document/index-quantities'
// import DetailForm from '../form'

export default {
  components: {
    DetailFull,
    PIDetail,
    Controls,
    DocumentQuantities
  },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      loaded: 'loaded'
    }),
    ...mapFields('orders', {
      order: 'item'
    }),
    ...mapFields('dashboards', {
      actions: 'actions'
    })
  },
  async created () {
    await this.$store.dispatch('productionInstances/_READ_FROM_DB', this.$route.params.id)
    await this.$store.dispatch('orders/_READ', this.item.order)
    await this.$store.dispatch('orders/_LOAD_JOBS_FOR_DOCUMENT')
  }
}
</script>
